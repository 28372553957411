<script>
import Navbar from "@/views/new-view/components/navbar";
import Footer from "@/components/footer";
import footerBar from "@/views/new-view/components/footerBar";
import indexApi from "@/apis/indexApi";
import planApi from "@/apis/planApi";
import storage from "@/storage";
import plans from "@/views/new-view/components/plans";

/**
 * vip
 */
export default {
  data() {
    return {
      url: "",
      imageLoad: true,
      wallet: undefined,
      dashBoard: undefined,
      plans: undefined,
      plansLoad: 0,
      login: false,
      planForm: {
        pageNum: 1,
        pageSize: 8
      },
      planType:'contracts'
    }
  },
  components: {
    Navbar,
    Footer,
    plans,
    footerBar
  },
  created() {
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
      //获取仪表盘信息
      indexApi.dashBoard(res => {
        if (res.code != 200) {
          storage.localDel("userId")
          this.login = false
        } else {
          this.dashBoard = res.data
        }
      })
    }
    //未登录时显示100条数据
    if (!this.login) {
      this.planForm.pageSize = 8
    }
    //获取项目列表
    planApi.vipList(this.planForm, res => {
      if(res.code === 403 || res.code === 400){
        window.location.href = "/page-error"
      }else{
        this.plans = res.data
      }
    })
  },
  methods: {
    loadmore() {
      this.planForm.pageNum++
      //开始加载
      this.plansLoad = 1
      let pages = Math.ceil(this.plans.totalCount / this.planForm.pageSize)
      //获取项目列表
      if (pages >= this.planForm.pageNum) {
        planApi.list(this.planForm, res => {
          res.data.result.unshift(...this.plans.result)
          Object.assign(this.plans, res.data)
          this.plansLoad = 0
          console.log(pages)
          console.log(this.planForm.pageNum)
          if (pages <= this.planForm.pageNum) this.plansLoad = -1//结束加载
        })
      } else {
        this.plansLoad = -1;
      }
    }
  },
};
</script>

<template>
  <div>
    <Navbar />
    <!-- 顶部 -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title" style="margin-top: -80px;">Choose Your Plans</h4><br/>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Choose Your Plans
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!-- 遮蔽罩 -->
    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->
    <!-- 登录与未登录通用 -->
    <section class="section pt-0">
      <div class="container" style="margin-top: 10px;padding-top: 10px">
        <el-row v-if="plans===undefined" v-loading="plans===undefined" element-loading-background="rgba(0, 0, 0, 0)">
        </el-row>
        <el-row v-else>
          <plans :data="plans.result" :type="planType"></plans>
        </el-row>

      </div>
    </section>

    <footerBar></footerBar>

  </div>
</template>

<style scoped lang="scss">
.page-next-level .page-next{
  z-index: 9;
}

.pt0 {
  background-color: #fffdfc;
}
</style>
