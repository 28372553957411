<script>
import date from "@/date"
import storage from "@/storage";
import PlanDetail from "@/components/planDetail.vue";
import BuyNow from "@/components/buyNow";
import indexApi from '@/apis/indexApi';

export default {
  metaInfo: {
    title: 'Discover the Best Free & Trusted Cloud Mining Contracts with IncCrypto: A New Energy Cloud Mining Company',
    meta: [
      { name: 'description', content: 'Unlock Your Mining Potential with IncCrypto - The Ultimate Destination for Free & Trusted Cloud Mining Contracts! Don\'t miss out on the best cloud mining site in the industry.' }
      // { property: 'og:title', content: 'My Open Graph Title' },
      // { property: 'og:description', content: 'My Open Graph Description' },
      // { property: 'og:image', content: 'http://example.com/image.jpg' }
    ],
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/plans' }
    ]
  },
  data() {
    return {
      PlanData: {},
      Show: false,
      showBuyNow: false,
      // plan: undefined
      bitCoin: '1',
      specialSales: [
          'Labor Day Special Sale - Cloud Mining',
          'Christmas Sale - Cloud Mining'
      ],
      ImageData: [
        {alt: 'cloud mining technology Services', title:'cloud mining technology Services'},
        {alt: 'cloud mining service provider', title:'cloud mining service provider'},
        {alt: 'Best Free Cloud Mining Sites', title:'best cloud mining contracts'},
        {alt: 'best cloud mining site', title:'new energy cloud mining company'},
        {alt: 'trusted cloud mining sites', title:'Best Free Cloud Mining Sites'},
        {alt: 'Trusted Free Cloud Mining Sites', title:'best cloud mining site'}
      ]
    }
  },
  props: {
    data: {
      Array
    },
    type:{
      String
    }
  },
  created() {
    // console.log(this.type)
    let source = sessionStorage.getItem('source') || ''
    indexApi.GetBitcoin(source,
        res => {
          // alert(res.data)
          this.bitCoin = res.data
        }
    )
  },
  filters: {
    getDate(data) {
      return date.getDate(data);
    }
  },
  computed: {
    dataList() {
      return this.data.filter(p => p.status === 1) // status 1 appear    2 disappear
    },
    isSpecialSale() {
      return item => this.specialSales.includes(item.name);
    }
  },
  methods: {
    textSplit(text = '') {
      console.log(text)
      if (!text || text.length <= 100) {
        return text
      }
      return text.substring(0, 101) + '...'
    },
    desc(data) {
      this.Show = true
      this.PlanData = data
      //判断是否为登录状态来觉得十分进入登录页面
      // if (storage.localGet("userId")) {
      //   this.Show = true
      //   this.PlanData = data
      // } else {
      //   this.Show = true
      //   this.PlanData = data
      //   // this.$message.warning("You need to login before you can do this")
      //   // this.$router.push("/auth-login")
      // }
    },
    buyNow(data) {
      //if login
      let userId = storage.localGet("userId")
      if (!userId) {
        this.$router.push("/auth-login")
      } else {
        this.showBuyNow = true
        this.PlanData = data
      }

    },
    buyNowModal() {
      // console.log('child showBuyNow:' + this.showBuyNow)
      // this.showBuyNow = !this.showBuyNow
      // console.log('showBuyNow change:' + this.showBuyNow)
    },
    ShowModal() {
      console.log("plans receive show:" + this.Show)
      this.Show = !this.Show
      console.log("plans show change:" + this.Show)
    }
  },
  components: {PlanDetail, BuyNow}
};
</script>

<template>
  <div class="plan-div">
    <el-row :gutter="20" type="flex" class="plan-row" justify="center">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" class="mt-4" v-for="(item, index) of dataList" :key="item.id">
        <div class="card " style="height: 100%;">
          <div class="ribbon ribbon-right ribbon-danger overflow-hidden" v-if="isSpecialSale(item)">
            <span class="text-center d-block shadow small h6">SALE</span>
          </div>
          <!--          style="background-color: #0a4499; border-radius: 0.25rem;color:white;"-->
          <div class="card-body">
            <!--            <h6 class="title font-weight-bold text-uppercase text-primary mb-4">-->
            <div class="card-img">
<!--              <img src="../../../assets/1.BCH.png" alt="">-->
              <div style="position: relative;">
                <img :src="item.presignedUrl" v-if="ImageData[index]" :alt="ImageData[index].alt" :title="ImageData[index].title">
                <img :src="item.presignedUrl" alt="bitcoin cloud mining companies" title="Bitcoin Free Cloud Mining" v-else>
<!--                <img :src="item.presignedUrl">-->
                <div v-if="item.progress === 100" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5);">
                  <img src="@/assets/img/soldout.png" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);width:180px;height: 180px;">
                </div>
              </div>
            </div>
            <div class="title ">
              {{ item.name }}
            </div>

            <!--              <div class="d-flex mb-4">-->
            <!--                <span class="h1 mb-0">{{ (item.percent * 100).toFixed(2) }}</span>-->
            <!--                <span class="h4 mb-0 mt-2">%</span>-->
            <!--              </div>-->
            <div class="price">
              <strong>${{ item.amount }} / {{ item.period | getDate }}</strong>
              <!--                <span class="price h4 mb-0"><strong>${{ item.amount }} / {{ item.period | getDate }}</strong></span>-->
            </div>
            <div class="split_line">

            </div>
            <div class="desc-box">
              <!--           text-muted   -->
              <div class="p">Contract Term: {{
                  item.period | getDate
                }}
              </div>

              <div class="p">Contract Price: ${{ item.amount }}
              </div>

              <!--              <div class="d-flex mb-4">-->
              <!--                <span>Include Maintenance Fee and Electricity Fee</span>-->
              <!--              </div>-->
              <div class="p">
                <span>Payment method: Cryptocurrency</span>
              </div>

              <div class="p">
                <span>Daily Rewards: {{ ((item.amount * item.percent)/bitCoin).toFixed(8) }}  BTC <br/>( ≈ ${{ (item.amount * item.percent).toFixed(2) }} )</span>
              </div>
              <template v-if="item.limitTime>1">
                <div class="p" v-if="item.showType===1">
                <span> Monthly revenue: >
                    {{((item.amount * item.percent * 30)/bitCoin).toFixed(8) }} BTC  <br/>( ≈ $ {{ (item.amount * item.percent * 30).toFixed(2) }} )</span>
                </div>
                <div v-if="item.showType===2" class="p">
                  <span>  {{ item.period === 1 ? '1 Day' : item.period + ' Days' }} Fixed Rewards: ${{item.amount }} + ${{ (item.amount * item.percent * item.period).toFixed(2) }}</span>
                </div>
              </template>

              <div class="p" v-if="item.showType===3">
                <span>{{ item.period === 1 ? '1 Day' : item.period + ' Days' }} Fixed Rewards: ${{ (item.amount * item.percent).toFixed(2) }}</span>
              </div>

<!--              <div class="p">-->
<!--                {{ textSplit(item.shortDesc) }}-->
<!--                <div class="p_show">{{ item.shortDesc }}</div>-->
<!--              </div>-->
            </div>
            <div class="btn-box">
              <button class="btn btn1 "
                      @click="desc(item)">Details
              </button>
              <button class="btn  btn2" v-b-modal="'plan-buy'" :disabled="item.progress === 100"
                      @click="buyNow(item)">Buy Now
              </button>
            </div>
            <el-row class="mt-2">
              <label style="font-size: 14px;">Sold out at 100%</label>
              <b-progress :max="100" animated>
                <b-progress-bar :value="item.progress" :variant="item.progress === 100 ? 'danger' : ''">
                  <span><strong>{{ item.progress }}%</strong></span>
                </b-progress-bar>
              </b-progress>
            </el-row>
          </div>
        </div>
      </el-col>
      <!--end col-->
    </el-row>
    <BuyNow :plan="PlanData" :showBuyNow="showBuyNow" @buyNowModal="buyNowModal"></BuyNow>
    <PlanDetail :item="PlanData" :show="Show" :bitCoin="bitCoin" @modal="ShowModal"></PlanDetail>

<!--    <section class="testimonial" v-if="this.type!=='contracts'">-->
<!--      <div class="container">-->
<!--        <h2>Unleash the Power of Cloud Mining with IncCrypto</h2>-->
<!--        <p class="mb-2">-->
<!--          <img src="@/assets/icon/pie-chart.png" alt="circular" style="width: 30px;" />-->
<!--          IncCrypto is your go-to destination for the best cloud mining experience. As one of the industry's most trusted cloud mining sites, we offer you the opportunity to mine cryptocurrencies hassle-free. Our platform provides the best cloud mining contracts for beginners and seasoned miners.-->
<!--        </p>-->
<!--        <p class="mb-2">-->
<!--          <img src="@/assets/icon/pie-chart.png" alt="circular" style="width: 30px;" />-->
<!--          At IncCrypto, we understand the importance of security and trust. That's why we have implemented advanced measures to ensure the safety of your investments and data. With our cutting-edge technology and state-of-the-art infrastructure, you can rest assured that your mining operations are in reliable hands.-->
<!--        </p>-->
<!--        <p class="mb-2">-->
<!--          <img src="@/assets/icon/pie-chart.png" alt="circular" style="width: 30px;" />-->
<!--          What sets us apart from the rest is our commitment to sustainable practices. As a new energy cloud mining company, we prioritize using renewable energy sources to power our mining operations. By partnering with us, you contribute to a greener future while reaping the benefits of cloud mining.-->
<!--        </p>-->
<!--        <p class="mb-2">-->
<!--          <img src="@/assets/icon/pie-chart.png" alt="circular" style="width: 30px;" />-->
<!--          Discover the best free cloud mining sites by visiting our website. Explore our plans at IncCrypto and start your mining journey with IncCrypto today. Experience the convenience, security, and profitability of cloud mining with a trusted industry leader.-->
<!--        </p>-->
<!--      </div>-->
<!--    </section>-->
  </div>

</template>

<style scoped lang="scss">
.plan-row {
  flex-wrap: wrap;
}

.testimonial {
  margin-top: 32px;
  h2 {
    text-align: center;
  }
}

.plan-div {
  background-color: #fffdfc;

  .card {
    //width: 237px;
    //height: 621px;
    background: #FFFFFF;
    //box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    cursor: pointer;
    transition: all .5s ease;

    &:hover {
      //box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      //background: rgba(255, 190, 61, 1);
      background: #384247;
      color: #ffffff;

      .card-body {
        .title {
          //color: rgba(12, 102, 255, 1);
          background: #ffffff;
          color: black;
        }

        .price {
          color: #ffffff;

        }

        .desc-box {
          .p {
            font-size: 16px;
            color: #ffffff;
          }
        }

        .btn-box {
          .btn1 {
            background: rgba(250, 251, 254, 1) !important;
          }
        }

        .card-img {
          img {
            transform: scale(1.1);
          }
        }
      }

    }

    .card-body {
      display: flex;
      flex-direction: column;

      .card-img {
        width: 100%;
        //height: 140px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          transition: all .3s ease;
        }

      }

      text-align: center;
      padding: 10px;

      .title {
        //width: 61px;
        //height: 24px;
        line-height: 24px;
        padding: 0 8px;
        background: black;
        //background: RGBA(12, 102, 255, 1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        border-radius: 100px;
        font-size: 14px;
        //font-family: PingFangSC-Regular, PingFang SC;
        color: #FFFFFF;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        margin: 20px auto 0;
        display: inline-block;
      }

      .price {
        font-size: 28px;
        //font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #273444;
        line-height: 40px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        margin: 12px auto 19px;
        text-align: center;
      }

      .split_line {
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(239, 242, 247, 0) 0%, #EFF2F7 50%, rgba(239, 242, 247, 0) 100%);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        opacity: 0.8;
      }

      .desc-box {
        text-align: center;
        padding: 16px 5px;

        .p {
          //width: 194px;
          //height: 20px;
          font-size: 16px;
          //font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          //color: #8492A6;
          color: #0a0a0a;
          line-height: 20px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
          margin: 13px 0;
          position: relative;

          .p_show {
            position: absolute;
            top: -200%;
            left: 0;
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8492A6;
            line-height: 20px;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            margin: 13px 0;
            display: none;
            //background-color: #ffffff;
            border-radius: 8px;
            padding: 8px;
            transition: all .5s ease;
            background-color: rgba(0, 0, 0, .8);

            &::after {
              position: absolute;
              content: "";
              display: inline-block;
              width: 0px;
              height: 0px;
              border: 6px solid rgba(0, 0, 0, .8);
              border-top-color: rgba(0, 0, 0, .8);
              border-bottom-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
              bottom: -10px;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &:last-child:hover {
            .p_show {
              display: block;
            }
          }
        }
      }

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 5px;
        margin-top: auto;

        .btn {
          width: 87px;
          height: 39px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          line-height: 39px;
          padding: 0;

          &.btn1 {
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #273444;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            //background: rgba(255, 190, 61, 1);
            background: #ff9259;
          }

          &.btn2 {
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            background: rgba(12, 102, 255, 1);
          }
        }
      }
    }
  }

  .progress-bar {
    background-color: #384247;
  }


}

//.plan-card{
//  height: 100%;
//  .card-body{
//    display: flex;
//    flex-direction: column;
//    justify-content: space-around;
//  }
//}
</style>
